/**
* Template Name: Chainxt
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #1b1919;
  font-size: 18px;
  line-height: 1.4;
}

@media screen and (max-width: 767px) {
  body{
    font-size: 16px;
  }
}

a {
  color: #761ac1cc;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #1b1919;
}
h1 {
  font-size: 45px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}

p{
  color: #3E3c3c;
  font-weight: 400;
}

strong{
  font-weight: 600;
}

/* button */
.btn{
  padding: 10px 30px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  background-color: #761ac1cc;
  color: #fff;
  border-radius: 4px;
  transition: 0.5s;
}
.btn:hover,
.btn.btn-primary:hover,
.btn:focus{
  background-color: #761ac1cc;
  color: #fff;
}

.btn.btn-primary{
  background-color: #761ac1;
  color: #fff;
  border: 2px solid #761ac1;
  box-shadow: none;
}

.btn.btn-secondry{
  background-color: #fff;
  color: #761ac1;
  border: 2px solid #761ac1;
  box-shadow: none;
}
.btn.btn-secondry:hover{
  background-color: #761ac1;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: none;
}

.btn span {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
}

.btn i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.btn:hover i {
  transform: translateX(5px);
}

select {
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
}

/* Background */
.bg-light-gray{
  background-color: #edf7ff;
}

/* Text Color */
.text-theme-color{
  color: #761ac1;
}

/* Section Heading */
.section-heading{
  font-size: 32px;
  font-weight: 500;
  text-transform: capitalize;
}
.section-heading-small{
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width:767px) {
  /* Section Heading */
  .section-heading{
    font-size: 28px;
  }
}

/* Container */
.custom-container-580{
  max-width: 580px;
}

/* Font */
.font-20{
  font-size: 20px;
}

@media screen and (max-width:767px) {
  .font-20{
    font-size: 18px;
  }
}

/* Main */
main#main {
  margin-top: 75px;
}

/*--------------------------------------------------------------
# Slider
--------------------------------------------------------------*/
.swiper-pagination .swiper-pagination-bullet{
  background-color: #fff;
  opacity: 1;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: #fff;
  width: 24px;
  border-radius: 25px;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

@media screen and (max-width:767px) {
  section {
    padding: 40px 0;
  }
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
  color: #1b1919;
}

.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 0;
  color: #761ac1cc;
  text-transform: uppercase;
}

.section-header p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 500;
  color: #012970;
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #012970;
  min-height: 40px;
  margin-top: 82px;
  color: #fff;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 57px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 500;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #8894f6;
  content: "/";
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #761ac1cc;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 4px 0;
  background-color: #fff;
}

.header.header-scrolled {
  background: #fff;
  padding: 4px 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}
.dashboard-content .header.header-scrolled{
  box-shadow: 0px 2px 2px rgba(1, 41, 112, 0.1);
}

.header .logo {
  line-height: 1;
}
.dashboard-content .header .logo {
  line-height: 1;
  display: inline-block;
}

.header .logo img {
  max-width: 195px;
  width: 100%;
  margin-right: 0px;
}

.header .logo span {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #012970;
  font-family: 'Poppins', sans-serif;
  margin-top: 3px;
}
a.logo-innowallet {	
  display: inline-block;	
  text-decoration: none;	
  color: #761AC1;	
  font-size: 20px;	
  line-height: 1;	
  font-weight: 500;	
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #761AC1;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #761ac1cc;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #761ac1cc;
  padding: 4px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #5969f3;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 500;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #761ac1cc;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .megamenu {
  position: static;
}

.navbar .megamenu ul {
  margin-top: 5px;
  right: 0;
  padding: 10px;
  display: flex;
}

.navbar .megamenu ul li {
  flex: 1;
}

.navbar .megamenu ul li a,
.navbar .megamenu ul li:hover>a {
  color: #761AC1;
}

.navbar .megamenu ul li a:hover,
.navbar .megamenu ul li .active,
.navbar .megamenu ul li .active:hover {
  color: #761ac1cc;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #012970;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 1200px) {
  .mobile-nav-toggle {
    display: block;
  }

  .header .navbar ul {
    display: none;
  }
  
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 22, 61, 0.9);
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #012970;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #761ac1cc;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #761ac1cc;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #f6f9ff;
  padding: 0 0 30px 0;
  font-size: 14px;
  border-top: 5px solid #761ac1;
}

.footer .footer-newsletter {
  padding: 50px 0;
  background: #f6f9ff;
  border-top: 1px solid #e1ecff;
}

.footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  color: #012970;
}

.footer .footer-newsletter form {
  margin-top: 20px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e1ecff;
}

.footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

.footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #761ac1cc;
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
}

.footer .footer-newsletter form input[type=submit]:hover {
  background: #5969f3;
}

.footer .footer-top {
  background: white url(../assets/img/footer-bg.png) no-repeat right top;
  background-size: contain;
  border-top: 1px solid #e1ecff;
  border-bottom: 1px solid #e1ecff;
  padding: 60px 0 30px 0;
}

@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}

.footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
  line-height: 0;
  margin-bottom: 15px;
}

.footer .footer-top .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-top .footer-info .logo span {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #012970;
  font-family: 'Poppins', sans-serif;
  margin-top: 3px;
}

.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: #012970;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: 500;
  color: #012970;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: #761AC1;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-top .footer-links ul a:hover {
  color: #761ac1cc;
}

.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer .copyright {
  text-align: center;
  padding-top: 30px;
  color: #012970;
}

.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  background: url(../assets/img/hero-banner.jpg) top center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  padding: 0;
}
.hero-banner{
  background-image: linear-gradient(to right, #dcb3ef 0%, #bc87d5 100%);
}
.hero-overlay{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: transparent;
  background-image: linear-gradient(90deg, #00080FCC 0%, #00080F00 100%);
  transition: background-image 0.3s, border-radius 0.3s, opacity 0.3s;
}
.hero h1 {
  margin: 0;
  font-size: 44px;
  font-weight: 300;
  color: #fff;
}

.hero h2 {
  color: #fff;
  margin: 15px 0 0 0;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 300;
}

.btn.btn-get-started {
  margin-top: 30px;
}

.hero .hero-img {
  text-align: right;
}

.hero-banner-slider{
  padding: 100px 0;
}

.hero-banner-slider .swiper-pagination{
  bottom: 20px;
}

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0;
  }
  .hero-banner-slider {
    padding: 70px 0;
  }

  .hero .hero-img {
    text-align: center;
  }

  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero h2 {
    font-size: 20px;
  }

  .hero .hero-img img {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/

/*--------------------------------------------------------------
## Programs Section
--------------------------------------------------------------*/
.custom-tab .nav .nav-link{
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  border: 2px solid #761AC1;
  color: #761AC1;
  margin-bottom: 8px;
  padding: 10px;
}
.custom-tab .nav .nav-link.active{
  color: #fff;
  background-color: #761AC1;
}
.card{
  border-radius: 4px;
}
.card figure{
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}
.card img{
  max-width: 100%;
  height: auto;
  transition: all 300ms ease;
  border-radius: 4px 4px 0 0;
}
.card:hover{
  box-shadow: 0px 0px 10px 3px #761ac13e;
}
.card:hover img{
  transform: scale(1.05);
}
.card .card-body{
  padding: 10px;
}
.card h2{
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
}
.card p{
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 0;
}


/*--------------------------------------------------------------
## Our Courses Section
--------------------------------------------------------------*/
.secondary-heading-rounded-border{
  padding: 10px 30px;
  border-radius: 4px;
  border: 2px solid #761ac1;
  display: inline-block;
  color: #761ac1;
  font-size: 16px;
}

/*--------------------------------------------------------------
## Academy Process Section
--------------------------------------------------------------*/
.academy-process-section p{
  line-height: 1.5;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Course Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Banner Section
--------------------------------------------------------------*/
.course-banner .btn-enroll{
  padding: 7.5px 30px;
}
.price-tag{
  padding: 8px 12px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #761AC1;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px 0 4px 0;
}

/* Accordion */
.accordion-header .accordion-button{
  font-size: 18px;
  font-weight: 500;
}
.accordion-header .accordion-button:focus{
  box-shadow: none;
  outline: none;
}
.accordion-body li,
.accordion-body li a{
  font-size: 16px;
  line-height: 1.5;
  display: inline-block;
  list-style-type: none;
}
.accordion-body a{
  text-decoration: none;
  transition: all 300ms ease;
}
.accordion-body a:hover{
  color: #761AC1;
}

/*--------------------------------------------------------------
# Login Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Login Section
--------------------------------------------------------------*/
.login-section,
.register-section {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0;
  padding: 0;
}
.login-section .right-col-image,
.register-section .right-col-image{
  /* background: url(../assets/img/hero-banner.jpg) top center no-repeat; */

  background: url('../assets/img/hero-banner_old.jpg') top center no-repeat;
    background-size: cover;
    width: calc(100% - 400px);
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.login-section .right-col-image::after,
.register-section .right-col-image::after{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* background-image: url('../assets/img/hero-banner_old.jpg'); */
  background-image: linear-gradient(to bottom, #761ac1cc 0%, #000000cc 100%);
}
.right-col-image .inner-container{
  max-width: 500px;
  width: 100%;
  padding: 20px;
  
}
.login-section .custom-container,
.register-section .custom-container{
  max-width: 400px;
  width: 100%;
}
.login-logo,
.register-logo{
  max-width: 200px;
}
.login-logo img,
.register-logo img{
  max-width: 100%;
}
.h-100vh{
  height: 100vh;
}
.login-section .login-content,
.register-section .register-content {
  padding: 15px 40px 30px;
  border-radius: 4px;
  background-color: #fff;
  border: 0px;
  width: 100%;
  max-width: 500px;
  max-height: 100vh;
  overflow: auto;
}
.login-section h1,
.register-section h1{
  font-size: 26px;
  text-align: center;
}
.login-section .login-content label,
.register-section .register-content label{
  font-size: 16px;
  line-height: 1;
  margin-bottom: 8px;
}
.forgot-pw-container .forget-password-link{
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 13px;
  line-height: 1;
}
.btn-submit,
.btn-login,
.btn-register{
  width: 100%;
}
.register,
.login{
  text-align: center;
}
.forget-password a{
  display: inline-block;
  font-size: 16px;
  color: #761AC1;
}
.btn-login-google{
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-login-google .google-icon{
  width: 15px;
  height: 15px;
  background-image: url(../assets/img/google-icon.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: left center;
}

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Sidebar
--------------------------------------------------------------*/

.dashboard-wrapper .header {
  padding: 10px 0;
  background-color: #edf7ff;
}
.dashboard-wrapper .sidebar {
  max-width: 200px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #edf7ff;
  border: 1px solid #edf7ff;
}
.small-sidebar.dashboard-wrapper .sidebar{
  max-width: 100px;
}
.sidebar .navbar{
  background-color: #edf7ff;
  padding-top: 75px;
}
.dashboard-wrapper .dashboard-content {
  max-width: calc(100% - 200px);
  min-height: 100vh;
  margin-left: 200px;
}
.small-sidebar.dashboard-wrapper .dashboard-content {
  max-width: calc(100% - 100px);
  margin-left: 100px;
}
.sidebar .logo-wrapper{
  padding: 10px 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar .logo-wrapper .navbar-brand.logo{
  display: inline-block;
  padding: 0;
  margin: 0;
}
.navbar-brand.logo img.large-logo{
  max-width: 160px;
}
.navbar-brand.logo img.small-logo{
  max-width: 56px;
  transform: scale(0.55);
  display: none;
}
.small-sidebar .navbar-brand.logo img.large-logo{
  display: none;
}
.small-sidebar .navbar-brand.logo img.small-logo{
  display: inline-block;
}

.admin-detail{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.admin-detail .avatar-image{
  max-width: 40px;
  border-radius: 100px;
  width: 40px;
  height: 40px;
}
#toggleMenuBar{
  font-size: 18px;
  line-height: 1;
  margin-left: 1.5rem;
}
#toggleMenuBar .cross-icon{
  display: none;
}
.small-sidebar #toggleMenuBar .cross-icon{
  display: block;
}
.small-sidebar #toggleMenuBar .menu-bar-icon{
  display: none;
}
#toggleMenuBar i{
  margin: 0;
}

/* Sidebar Menu */
#navbarSidebar {
  width: 100%;
}
#accordionMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#accordionMenu li {
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}
#accordionMenu li .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
#accordionMenu li .accordion-button,
#accordionMenu li a {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  width: 100%;
  padding: 15px 15px;
  margin: 0;
  background: #fff;
  border-bottom: 0;
  border-radius: 0px;
  transition: all 300ms ease;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #edf7ff;
}
.small-sidebar #accordionMenu li .accordion-button span,
.small-sidebar #accordionMenu li a span {
  display: none;
}
#accordionMenu li li a{
  border-bottom: 1px solid #fff;
}
#accordionMenu li i{
  font-size: 18px;
  line-height: 1;
  margin-left: 0;
}
#accordionMenu li .accordion-button::after{
  color: #761ac1;
  margin: 0;
  right: 15px;
  position: absolute;
}
#accordionMenu li .accordion-button:not(.collapsed),
#accordionMenu li .accordion-button:hover,
#accordionMenu li a:hover,
#accordionMenu li .accordion-button.active,
#accordionMenu li a.active {
  background-color: #761AC1;
  color: #fff;
  border-bottom: 1px solid #edf7ff;
}
#accordionMenu li .accordion-button:not(.collapsed)::after,
#accordionMenu li .accordion-button:hover::after{
  filter: invert(1);
}
#accordionMenu li ul {
  flex-wrap: wrap;
  flex-direction: column;
}
#accordionMenu li ul a{
  background-color: #edf7ff;
}
#accordionMenu li li a{
  padding: 15px 15px 15px 25px;
} 

/* Dashboard */
.dashboard {
  height: calc(100% - 115px);
  padding: 1rem;
}


/*-------------- Sudershan's css---------------- */
.home-container{
  margin-left: 0px !important;
}


.githubLogoCls{
  height: 20px;
  margin: 5px;
}

.letsstartbtn{
  padding: 3px;
}