@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

body{
  font-family: 'Poppins', sans-serif;
}

.parent-container{
    /* align-items: left; */
    /* display: flex; */
    flex-direction: column;
    /* margin-top: 2rem; */
    /* width: 45%; */
    /* padding-top: 10%; */
    margin-left: 0%;
    padding-left: 10%;
    padding-right: 10%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    .parent-container {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .parent-container input{
    margin: 0.1rem;
  }
  
  .add-passcode-button{
    background-color: #D3D3D3;
    padding: 10px;
    width: 50%;
    border-radius: 5px;
  }
  
  .wallet-buttons-container{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .wallet-buttons-container button{
    background-color: #D3D3D3;
    padding: 10px;
    width: 100%;
    margin: 10px;
    border-radius: 5px;
  }
  
  .import-screen-container{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .import-screen-btn-container{
    padding: 25px;
  }
  
  .import-screen-btn-container button{
    background-color: #D3D3D3;
    padding: 10px;
    width: 100px;
    margin: 10px;
    border-radius: 5px;
  }
  
  .mnemonic-textarea{
    width: 400px;
    height: 75px;
  }
  
  .wallet-container{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .wallet-container button{
    background-color: #D3D3D3;
    padding: 10px;
    width: 300px;
    margin: 10px;
    border-radius: 5px;
  }
  
  .export-mnemonic-container{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .export-mnemonic-container button{
    background-color: #D3D3D3;
    padding: 5px;
    width: 25%;
    margin-top: 2px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .fund-account-container{
  
  }
  
  .account-detail-container{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .send-ether-textarea{
    width: 400px;
    height: 35px;
  }
  
  .send-ether-container{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .send-ether-container input{
    margin: 10px;
    width: 100px;
  }
  
  .send-ether-container button{
    background-color: #D3D3D3;
    padding: 10px;
    width: 100px;
    margin: 10px;
    border-radius: 5px;
  }
  
  .accounts-dropdown-container {
    padding-left: 15%;
    padding-right: 15%;
  }
  
  /* --------------Popup----------- */
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
    
  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
    max-width: 300px;
    width: 100%;
  }
  
  .popup ul{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .popup li {
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .popup li:hover {
    background-color: #f2f2f2;
  }
  
  .popup li.selected{
    background-color: #ccc;
  }
  
  /* Alert css */
  .custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .custom-modal-content {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content button {
    margin-top: 20px;
  }
  
  .add-account-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
  }
  
  .add-account-container input{
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 5px;;
  }
  
  .add-account-buttons-container{
    padding: 10px;
  }
  
  .add-account-buttons-container button{
    background-color: #D3D3D3;
    padding: 10px;
    width: 100px;
    margin: 10px;
    border-radius: 5px;
  }
  
  .connected-text{
    color: green;
    font-size: 12px;
    margin: 10px;
    padding-left: 40%;
  }
  
  .not-connected-text{
    color: red;
    font-size: 12px;
    margin: 10px;
  }
  
  .SpinnerCls {
    margin-top:25%;
  }
  
  .SpinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9998;
    cursor: not-allowed;
  }

  .passwordMismatchLabel{
    color: #ff0000;
    font-size: 0.75rem;
  }

  .validate-passcode-container{
    /* padding-top: 11rem; */
  }

  .child-container{

  }

  .passcode-title{
    font-size: 1.5rem;
  }

  /* .validate-passcode-input{
    margin: 1rem !important;
  } */

  .child-wallet-container{
    
  }

  .logo_container{
    position: absolute;
    top: 20px; 
    left: 20px; 
  }

  .logo_image {
    height:80px;
  }

  .product_title_container{
    position: absolute;
    top: 20px; 
    right: 20px; 
  }

  .product_title_text{
    color: #7c1ac1;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
  }

  .product_footer_container{
    position: absolute;
    bottom: 20px; 
    right: 20px; 
    color: #7c1ac1;
  }

  .product_footer_text{
    color: #fff;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }

  /* InnoWalletix title */

    .product-title{
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 1.2rem;
    }

    .product-title a{
      text-decoration: none;
      color: #7c1ac1;
    }

    .githubLogoWalletCls{
      width:100%;
      margin-top: 16px;
    }

    .account-detail-name{
      font-family: 'Poppins', sans-serif;
      font-size: 1rem;
      color: #7c1ac1;
    }
    .account-detail-address{
      font-family: 'Poppins', sans-serif;
      font-size: 1rem;
      color: #7c1ac1;
    }
    .account-detail-balance{
      font-family: 'Poppins', sans-serif;
      font-size: 1.25rem;
      color: #7c1ac1;
    }